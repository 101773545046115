html, body {
  background: #fff;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
}

* {
  box-sizing: border-box;
}

form {
  flex-direction: column;
  flex: 1;
  justify-content: stretch;
  align-items: stretch;
  display: flex;
}

.clickable {
  cursor: pointer;
}

h1, h1, h3, h4, h5 {
  margin: 0;
}

@keyframes show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
/*# sourceMappingURL=index.a71d0aaf.css.map */
