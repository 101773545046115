html,
body {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    background: #ffffff
}

body {
    font-size: 16px;
    font-family: "proxima-nova", sans-serif;
}

* {
    box-sizing: border-box;
}

form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    flex: 1;
}

#app {
    /*width: 100%;*/
    /*height: 100%*/
}

.clickable {
    cursor: pointer;
}


h1, h1, h3, h4, h5 {
    margin: 0 0 0 0;
}


@keyframes show {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}